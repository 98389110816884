import React from 'react'
import { graphql } from 'gatsby'
import FestifHelmet from '../../components/Helmet/FestifHelmet'
import Wolflayout from '../../components/wolflayout'
import FilteredActivities from '../../components/NewTheme/Programmation/FilteredActivities'
import InfosNavigation from '../../components/Infos/InfosNavigation'
import InfosHeader from '../../components/Infos/InfosHeader'
import { StaticImage } from 'gatsby-plugin-image'

const Famille  = ({data}) => {
  const metaTitle = 'Famille | Le Festif! de Baie-Saint-Paul'
  const metaDescription = ''
  const metaKeywords = 'festival, musique, évènement, spectacles, québec, charlevoix, baie-saint-paul, famille'

  const familleEvent = data.allPrismicEvents.edges.filter(({ node }) => {
    return node.data.isfamille === true
  })

return (
  <Wolflayout mobileTitle={'Bien-Activités familiales'}>
      <FestifHelmet title={metaTitle} description={metaDescription} keywords={metaKeywords} />
      <InfosNavigation />
      <InfosHeader title="Activités familiales" />
      <div className="container content pb-12">

        {/* TOP TEXT */}
        <div className='text-center'>
            <h4 className='!font-obviously !font-regular !text-xl'>Le Festif!, c’est pour tout le monde! Maman, papa, pas besoin de faire garder les p’tit·es, parce qu’on a de quoi les divertir. Entre Les arts de la rue Télé-Québec, où acrobates, jongleur·euses, fanfares et musicien·nes ambulant·es envahissent la rue Saint-Jean-Baptiste, les ateliers de cirque et de bricolage ou encore la BDthèque, notre camion mobile rempli de bandes dessinées, l’heure de la sieste risque de prendre le bord… mais on se dédouane d’avance pour ça!</h4>
        </div>

        {/* PRESENTED BY */}
        <div>
          <h2 className="!font-obviously !font-semibold !text-lg !ml-9 !pl-0 !mb-0 !pb-0">Présentée par Télé-Québec</h2>
          <div className="max-w-[250px] !py-0 ml-4">
              <a href="https://www.telequebec.tv/">
                <StaticImage src="../../images/partenaires/telequebec-lafrabriqueculturelle.png" alt="Logo Télé-Québec | La Fabrique Culturelle" />
              </a>
          </div>
        </div>

        {/* PROG */}
        <div>
          <FilteredActivities eventList={familleEvent} />
        </div>

        {/* LQ BANNER */}
        <div className="w-full center mt-16 mb-0 px-6">
          <div className="max-w-[728px] max-h-[90px] mx-auto">
            <a className="" href="https://www.lafabriqueculturelle.tv/10ans">
              <StaticImage src="../../images/partenaires/telequebec-banner.jpg"/>
            </a>
          </div>
        </div>

      </div>
  </Wolflayout>
)
}

export const query = graphql`
  query {
    allPrismicEvents {
      edges {
        node {
          uid
          slug
          data {
            main_image {
              alt
              copyright
              gatsbyImageData
              url
            }
            title {
              text
            }
            start_time
            end_time
            description {
              text
            }
            lpdv_id {
              text
            }
            is_show
            free
            isbienetre
            isentrevue
            isconference
            isplaisirdivers
            isfamille
            hidefromprog
            stage {
              document {
                ... on PrismicStages {
                  uid
                  slug
                  data {
                    title {
                      text
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default Famille